import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllMedias extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag(`Files/GetList`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

export {
  GetAllMedias
}
