import WebServiceRequest from '../Api/WebServiceRequest'

class GetGameServicesByGameServiceCategoryId extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag(`Game/GetGameServicesByGameServiceCategoryId`)

  }


  setQuery(data) {
    super.setRequestParam(data)

  }
}

class DeleteGameService extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag(`Game/DeleteGameService`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class DeleteGameServiceOffer extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag(`Game/DeleteGameServiceOffer`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class CreateGameServiceOffer extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag(`Game/CreateGameServiceOfferBy`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetGameServiceDetail extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
  }

  setPartial(data) {
    super.setTag(`Game/GetGameServiceBy/${data.id}`)

  }
}
class UpdateGameServiceOffers extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Game/UpdateGameServiceOfferListBy')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)

  }
  setQuery(data){
    super.setRequestParam(data)

  }
}

class CreateGameService extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag(`Game/CreateGameService`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setForm(data) {
    super.setFormData(data)
  }
}

class CreateGameServiceContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('POST')
    super.setTag(`Game/CreateGameServiceContentSection`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setPayload(data) {
    super.setRequestParamDataObj(data)
  }
}

class DeleteGameServiceContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('DELETE')
    super.setTag(`Game/DeleteGameServiceContentSection`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class GetGameServiceContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
  }

  setPartial(data) {
    super.setTag(`Game/GetGameServiceContentSectionBy/${data.id}`)

  }
}

class UpdateGameServiceContent extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag(`Game/UpdateGameServiceContentSection`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setPayload(data) {
    super.setRequestParamDataObj(data)
  }
}

class UpdateGameService extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag(`Game/UpdateGameService`)
  }

  setParams(data) {
    super.setRequestParam(data)
  }

  setForm(data) {
    super.setFormData(data)
  }
}

export {
  GetGameServicesByGameServiceCategoryId,
  DeleteGameService,
  CreateGameService,
  GetGameServiceDetail,
  DeleteGameServiceOffer,
  CreateGameServiceOffer,
  UpdateGameService,
  CreateGameServiceContent,
  DeleteGameServiceContent,
  GetGameServiceContent,
  UpdateGameServiceContent,
  UpdateGameServiceOffers

}
