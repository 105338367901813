<template>
  <div class="d-flex align-items-center flex-wrap ">
    <div class="">
      <input ref="fileHandler" :accept="acceptMimeTypes" :multiple="multiple" class="d-none" type="file"
             @input="generateFiles"
      >
      <div class="  border rounded shadow cursor-pointer d-flex align-items-center justify-content-center"
           style="height: 10rem;width: 10rem"
           @click="$refs.fileHandler.click()"
      >
        <feather-icon icon="PlusIcon"></feather-icon>
      </div>
    </div>
    <template v-if="fileData && fileData.length>0">
      <div v-for="(item,idx) in fileData" class="">
        <div
            class=" position-relative   mx-25 rounded   shadow   m-0    "
            style="height: 10rem;width: 10rem"
        >
          <span
              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"
              style="width: 2rem;height: 2rem" @click="removePicture(idx)"
          >
            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>
          </span>
          <img :src="item.pictureUrl" alt="" class="rounded" style="object-fit: contain;height: 10rem;width: 10rem">
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import MediaModal from '@/views/components/utilities/MediaModal.vue'

export default {
  name: 'MediaHandler',
  components: { MediaModal },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    acceptMimeTypes: {
      type: String,
      default: 'image/*'
    }
  },
  data() {
    return {
      fileData: []
    }
  },
  methods: {
    clearMedia() {
      this.fileData = []
      this.$refs.fileHandler.value = ''
    },
    sendFiles() {
      let clonedFiles = []
      this.fileData.forEach((item) => {
        clonedFiles.push(item.file)
      })
      this.$emit('sendData', this.multiple ? clonedFiles : clonedFiles[0])
    },
    removePicture(idx) {
      this.fileData.splice(idx, 1)
      this.$refs.fileHandler.value = ''
    },
    openMediaModal() {
      this.$bvModal.show('media-controller')
    },
    generateFiles() {
      this.$refs.fileHandler.files.forEach((item) => {
        if (!this.multiple) {
          this.fileData = []
        }
        this.fileData.push({
          file: item,
          pictureUrl: URL.createObjectURL(item)
        })
      })
    },
  }
}
</script>

<style scoped>

</style>
