<template>
  <div id="breadcrumb-alignment">
    <div class="d-flex p-2 justify-content-start flex-wrap breadcrumb-wrapper">
      <template v-for="(item,idx) in items" >
      <b-link  :to="item.href">
        <span :class="{'text-dark':!item.active}" >{{item.text}}</span>
      </b-link>
      <span v-if="idx!== items.length-1" class="mx-25">/</span>
      </template>
    </div>

  </div>
</template>

<script>
import { BBreadcrumb,BLink } from 'bootstrap-vue'

export default {
  name: 'DynamicBreadCrumb',
  components: {
    BBreadcrumb,
    BLink
  },
  props: {
    items: {
      type: Array
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
