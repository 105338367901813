<template>
  <b-overlay :show="showOverlay">
    <b-modal id="media-controller"
             cancelTitle="Cancel"
             centered
             size="lg"
             ok-title="Create"
             @ok.prevent="sendUrls"
    >
      <b-row>
        <b-col></b-col>
      </b-row>
      <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalCount"
          class="mb-0 mt-1 mt-sm-0"
          first-number
          last-number
          next-class="next-item"
          prev-class="prev-item"
      >
        <template #prev-text>
          <feather-icon
              icon="ChevronLeftIcon"
              size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
              icon="ChevronRightIcon"
              size="18"
          />
        </template>
      </b-pagination>
    </b-modal>
  </b-overlay>

</template>

<script>
import { BModal, BOverlay, BPagination,BRow,BCol } from 'bootstrap-vue'

export default {
  name: 'MediaModal',
  components: {
    BModal,
    BOverlay,
    BPagination,
    BRow,
    BCol
  },
  data() {
    return {
      showOverlay: false,
      totalCount: 0,
      perPage: 10,
      currentPage: 1

    }
  },
  async mounted() {
    await Promise.all([
      this.getAllMedias()
    ])
  },
  methods: {
    sendUrls() {

    },
    async getAllMedias() {
      let _this = this
      _this.showOverlay = true
      let getAllMedias = new GetAllMedias(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
      }
      getAllMedias.setParams(data)
      await getAllMedias.fetch((content) => {
        _this.showOverlay = false
        console.log(content)
      }, (e) => {
        console.log(e)
      })

    }
  }
}
</script>

<style scoped>

</style>
