// V 2.6
import mainBaseUrl from '@/global-components'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

class Helper {
  static createFileAsBase64Image(fileObject, _callback) {
    const reader = new FileReader()
    reader.onload = (e) => {
      _callback(e.target.result)
    }
    reader.readAsDataURL(fileObject)
  }

  static convertNumbers2English(number) {
    return number.replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) & 0xf
    })
  };

  static getCurrencySymbol(data) {
    let symbols = {
      'dollar': '$',
      'euro': '€',
      '$': '$',
      '€': '€',
    }
    return symbols[data]
  }

  static readAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result.split(',')[1])
      }
      fr.readAsDataURL(file)
    })
  }

  static tellTheTime(time) {

    switch (typeof time) {
      case 'number':
        break
      case 'string':
        time = +new Date(time)
        break
      case 'object':
        if (time.constructor === Date) time = time.getTime()
        break
      default:
        time = +new Date()
    }
    let time_formats = [
      [60, 'seconds ', 1], // 60
      [120, '1 minute ago', 'In 1 minute'], // 60*2
      [3600, 'minutes ', 60], // 60*60, 60
      [7200, '1 hour ago', 'In 1 hour'], // 60*60*2
      [86400, 'hours ', 3600], // 60*60*24, 60*60
      [172800, 'yesterday', 'tomorrow'], // 60*60*24*2
      [604800, 'days ', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'last week', 'next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'last month', 'next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'last year', 'next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12

    ]
    let seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1

    if (seconds === 0) {
      return 'right now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds)
      token = 'from now'
      list_choice = 2
    }
    let i = 0,
      format
    while (format = time_formats[i++]) {
      if (seconds < format[0]) {
        if (typeof format[2] == 'string') {
          return format[list_choice]
        } else {
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
        }
      }
    }
    return time

  }

  static openLoadingOverlay(context) {
    context.$store.commit('loadingOverlayStore/showLoadingOverlay')
  }

  static makeFormData(data) {
    let myForm = new FormData()
    for (let key in data) {
      if (!Array.isArray(data[key])) {
        myForm.append(key, data[key])
      } else {
        data[key].forEach((item) => {
          myForm.append(key, item)
        })
      }
    }
    return myForm
  }

  static closeLoadingOverlay(context) {
    context.$store.commit('loadingOverlayStore/hideLoadingOverlay')
  }

  static showResponseModal(context, title, isWarning = false) {
    context.$store.commit('responseModal/toggleResponseModal')
    context.$store.commit('responseModal/setTitle', title)
    context.$store.commit('responseModal/setWarning', isWarning)
    context.$store.commit('responseModal/setCallbackUrl', callbackUrl)
  }

  static prettyPrice(price, length, decimalDelimiter, sectionDelimiter) {
    let c,
      d,
      t,
      s,
      i,
      j
    c = isNaN(length = Math.abs(length)) ? 2 : length
    d = decimalDelimiter === undefined ? ',' : decimalDelimiter
    t = sectionDelimiter === undefined ? ',' : sectionDelimiter
    s = price < 0 ? '-' : ''
    i = parseInt(price = Math.abs(+price || 0)
      .toFixed(c)) + ''
    j = (j = i.length) > 3 ? j % 3 : 0
    return s + (j ? i.substr(0, j) + t : '') + i.substr(j)
      .replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(price - i)
      .toFixed(c)
      .slice(2) : '')
  }

  static showLoginModal(context, title, description) {
    context.$store.commit('LoginModal/toggleLoginModal')
    context.$store.commit('LoginModal/setTitle', title)
    if (title !== '') {
      context.$toastMessage(context, description, 'error')
    }
  }

  static showRefreshSearchModal(context, title, description) {
    context.$store.commit('RefreshSearchModal/toggleRefreshSearchModal')
    context.$store.commit('RefreshSearchModal/setTitle', title)
    if (title !== '') {
      context.$toastMessage(context, description, 'error')
    }
  }

  static arrayRemove(arr, value) {

    return arr.filter(function (ele) {
      return ele !== value
    })
  }

  static isAdded(array) {
    return array === param
  }

  static myJoin(arr, sep = ',') {
    arr = arr.map(e => [undefined, null].includes(e) ? '' : e)
    let result = ''
    arr.forEach((e, i) => result += `${i ? sep : ''}${e}`)
    return result
  }

  static generateExcel(response) {
    const file = new Blob([response.data], {
      type: 'application/vnd.ms-excel'
    })
    return { file }
  }

  static validateForm(context, ref, SuccessCallback) {
    context.$refs[ref].validate()
      .then(success => {
        if (success) {
          return SuccessCallback()
        } else {
          context.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `warning`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `please fill in the required fields`,
            },
          })
        }
      })
  }

  static baseUrl = mainBaseUrl
  static externalLinkBaseurl = 'https://skycoach.oregonserver.com/'
}

export default Helper
